import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import queryString from "query-string"
import Moment from 'react-moment';
import Axios from "axios"

import Layout from "../components/layout"
import Seo from "../components/seo"

const DraftPageGroup = ({ location }) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(`https://lifefix.microcms.io/api/v1/portal-group/${contentId}?draftKey=${draftKey}`, {
      headers: {
        'X-API-KEY': "fb939c1a-996a-416b-98b4-85a697b5bf4f",
      },
    })
      .then((res) => res.json())
      .then(res => setData({ microcmsPortalGroup: res }));  // ※1
  }, []);  // ※2

  if (data === null) {
    return null;
  }

  return (
    <Layout>
      <Seo
        title={data.microcmsPortalGroup.title}
        description={data.microcmsPortalGroup.abstract}
      />

      <section className="hero">
        <figure>
          <img src={data.microcmsPortalGroup.thumbnail.url} />
        </figure>
      </section>

      <section className="bg-base-main px-8 py-4">
        <div className="max-w-5xl mx-auto">

          <div className="flex flex-wrap">
            <div className="">
              <Link to={`/`}>
                <button className="text-secondry-main text-sm underline hover:no-underline">TOP</button>
              </Link>
              <span className="mx-4 text-sm">></span>
              <Link to={`/group/1`}>
                <button className="text-secondry-main text-sm underline">団体をみる</button>
              </Link>
              <span className="mx-4 text-sm">></span>
              <span className="text-base-dark text-sm non-underline">
                {data.microcmsPortalGroup.accountName}
              </span>
            </div>
          </div>

        </div>
      </section>

      <section className="bg-base-main mx-8">
        <div className="max-w-5xl mx-auto">
          <div className="flex w-full py-6">
            <img src={data.microcmsPortalGroup.accountThumbnail.url} className="rounded-lg" width="180" height="180" />
            <div className="px-8">
              <h1 className="text-3xl text-base-dark font-bold leading-none mb-3">
                {data.microcmsPortalGroup.accountName}
              </h1>
              <div className="flex space-x-4 pt-16">
                {(() => {
                  if (data.microcmsPortalGroup.snsUrlFb != null) {
                    return (
                      <Link to={`${data.microcmsPortalGroup.snsUrlFb}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-60">
                        <StaticImage src="../../svg/icon_facebook.svg" alt="" />
                      </Link>
                    )
                  }
                })()}
                {(() => {
                  if (data.microcmsPortalGroup.snsurl != null) {
                    return (
                      <Link to={`${data.microcmsPortalGroup.snsurl}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-60">
                        <StaticImage src="../../svg/icon_twitter.svg" alt="" />
                      </Link>
                    )
                  }
                })()}
                {(() => {
                  if (data.microcmsPortalGroup.hpurl != null) {
                    return (
                      <Link to={`${data.microcmsPortalGroup.hpurl}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-60">
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z" fill="#57A44D" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M32.4997 16.4583C41.3592 16.4583 48.5413 23.6404 48.5413 32.4999C48.5413 41.3595 41.3592 48.5416 32.4997 48.5416C23.6401 48.5416 16.458 41.3595 16.458 32.4999C16.458 23.6404 23.6401 16.4583 32.4997 16.4583ZM19.4704 30.9065C19.8955 31.4506 21.0777 32.1469 22.8868 32.715C23.599 32.9387 24.3791 33.1353 25.2161 33.3023C25.2107 33.0364 25.208 32.7689 25.208 32.4999C25.208 27.6471 26.0911 23.2647 27.6209 20.3116C23.2599 22.0589 20.0547 26.0788 19.4704 30.9065ZM25.3963 36.3074C23.0545 35.9003 21.0466 35.2842 19.5248 34.4916C20.2327 39.1414 23.3804 42.9893 27.6209 44.6882C26.504 42.5321 25.7318 39.6141 25.3963 36.3074ZM28.3856 36.696C29.6939 36.8139 31.0718 36.8749 32.4997 36.8749C33.9046 36.8749 35.2887 36.7953 36.6199 36.6476C35.9621 41.8941 34.1432 45.6249 32.4997 45.6249C30.8612 45.6249 29.0485 41.917 28.3856 36.696ZM36.8547 33.6732C35.4699 33.8525 33.999 33.9583 32.4997 33.9583C30.9769 33.9583 29.5151 33.8828 28.147 33.7398C28.1323 33.332 28.1247 32.9184 28.1247 32.4999C28.1247 25.1849 30.4487 19.3749 32.4997 19.3749C34.5507 19.3749 36.8747 25.1849 36.8747 32.4999C36.8747 32.8956 36.8679 33.2869 36.8547 33.6732ZM39.6152 36.185C39.2865 39.5421 38.5091 42.5055 37.3785 44.6882C41.6938 42.9593 44.8774 39.005 45.5097 34.2447C43.9636 35.0489 41.9274 35.7213 39.6152 36.185ZM45.5204 30.8377C44.8341 31.608 42.6696 32.5229 39.7858 33.1622C39.7895 32.9425 39.7913 32.7217 39.7913 32.4999C39.7913 27.6471 38.9082 23.2647 37.3785 20.3116C41.7187 22.0505 44.9142 26.0407 45.5204 30.8377Z" fill="white" />
                        </svg>
                      </Link>
                    )
                  }
                })()}
                {(() => {
                  if (data.microcmsPortalGroup.snsUrlInstagram != null) {
                    return (
                      <Link to={`${data.microcmsPortalGroup.snsUrlInstagram}`} target="_blank" rel="noopener noreferrer" className="hover:opacity-60">
                        <StaticImage src="../../svg/icon_insta.svg" alt="" />
                      </Link>
                    )
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-base-main py-8 mx-8">
        <div className="container max-w-5xl mx-auto m-8">

          <div className="p-4 border-2 border-base-dark bg-white rounded-lg">
            <div className="font-bold">
              この団体とは？
            </div>
            <div className="postbody font-medium"
              dangerouslySetInnerHTML={{
                __html: `${data.microcmsPortalGroup.abstract}`,
              }}
            />
          </div>

        </div>
      </section>

      <section className="bg-base-main px-8">
        <div className="max-w-5xl mx-auto">

          <div className="p-4 border-2 border-base-dark bg-white rounded-lg">
            <div className="postbody font-medium"
              dangerouslySetInnerHTML={{
                __html: `${data.microcmsPortalGroup.body}`,
              }}
            />
          </div>

        </div>
      </section>
    </Layout>
  )
}

export default DraftPageGroup
